/* Custom Fonts*/
@font-face {
  font-family: Centra;
  src: url("./assets/fonts/CentraNo2-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url("./assets/fonts/CentraNo2-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url("./assets/fonts/CentraNo2-Book.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Pixelify;
  src: url("./assets/fonts/PixelifySans-Regular.ttf");
}

@font-face {
  font-family: Obitron;
  src: url("./assets/fonts/Orbitron-SemiBold.ttf");
}

/* Default CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: "Centra", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
  font-family: "Obitron", sans-serif;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 9.4rem;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}
/* Navbar Css */
nav.navbar {
  padding: 1.2rem 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
.navbar-logo-img {
  max-width: 6rem;
  height: auto;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
  width: 20%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 1.5rem;
  font-size: 1.2rem;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.social-icon {
  display: inline-block;
  margin-left: 1rem;
}
.social-icon a {
  width: 2.7rem;
  height: 2.7rem;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 1rem 1rem;
  font-size: 1rem;
  margin-left: 1rem;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 1.5rem;
  height: 1rem;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 1.5rem;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: .5rem;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(.5rem) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/* Banner Css */
.banner {
  margin-top: 0;
  position: relative;
  padding: 18rem 0 8rem 0;
  background-image: url("./assets/img/background.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.banner .tagline {
  font-family: "Pixelify", sans-serif;
  font-weight: 700;
  color: #038f03;
  letter-spacing: 0.8px;
  padding: .5rem .7rem;
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 5rem;
  margin-bottom: 1rem;
  display: inline-block;
}
.banner h1 {
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 1.75rem;
  display: block;
}
.banner p {
  color: #b8b8b8;
  font-size: 1rem;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  margin-top: 6rem;
}
.banner button {
  background-color: #1c87c9;
  -webkit-border-radius: 0.75rem;
  border-radius: 0.75rem;
  border: none;
  color: #eeeeee;
  cursor: pointer;
  display: inline-block;
  font-family: sans-serif;
  font-size: 1.25rem;
  padding: .75rem 1rem;
  text-align: center;
  text-decoration: none;
  margin-right: .75rem;
  margin-top: 4rem;
  animation: glowing 1300ms infinite;
}
@keyframes glowing {
  0% {
    background-color: #026b02;
    box-shadow: 0 0 5px #026b02;
  }
  50% {
    background-color: #038f03;
    box-shadow: 0 0 20px #038f03;
  }
  100% {
    background-color: #026b02;
    box-shadow: 0 0 5px #026b02;
  }
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

.wordCarousel ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.wordCarousel li {
  color: #9fcafb;
  font-size: larger;
  font-weight: 700;
  padding: 0 0.7rem;
  margin-bottom: 1.75rem;
  opacity: 0;
  position: absolute;
  animation: fadeInOut 12.5s infinite;
  display: block;
  width: 100%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.wordCarousel li:nth-child(1) {
  animation-delay: 0s;
}
.wordCarousel li:nth-child(2) {
  animation-delay: 2.5s;
}
.wordCarousel li:nth-child(3) {
  animation-delay: 5s;
}
.wordCarousel li:nth-child(4) {
  animation-delay: 7.5s;
}
.wordCarousel li:nth-child(5) {
  animation-delay: 10s;
}

@keyframes fadeInOut {
  0%,
  10% {
    opacity: 0;
  }
  15%,
  30% {
    opacity: 1;
  }
  35%,
  100% {
    opacity: 0;
  }
}

/* Skills Css */
.skill {
  padding: 0 0 3rem 0;
  position: relative;
}
.skill-bx {
  background: #151515;
  border-radius: 4rem;
  text-align: center;
  padding: 3.5rem 3rem;
  margin-top: -60px;
}
.skill h2 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.skill-slider {
  width: 90%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 1rem auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/* Projects Css */
.project {
  padding: 5rem 0;
  position: relative;
  overflow: hidden;
}
.tabs {
  margin-bottom: 1rem;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 3rem;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 1rem 0;
  color: #fff;
  width: 100%;
  font-size: 1rem;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #162811 -5.91%, #f7e79e 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}
.project::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: -2;
}
.project h2 {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
}
.proj-imgbx {
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
  margin-bottom: 1.75rem;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #162811 -5.91%, #f7e79e 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  padding: 0 9px;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 10%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -1;
}

.projectbutton button {
  width: 90%;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 1rem 3rem;
  font-size: 1rem;
  margin-top: 1.5rem;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.projectbutton button:hover{
  color: #fff;
  background-color: #000;
}

/* Experience Css */
.experience {
  padding: 5rem 0;
  position: relative;
}

.experience h2 {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
}

.experiences {
  text-align: left;
}

.experiences h3 {
  font-size: 1.7rem;
  padding-top: 1rem;
  color: #038f03;
}

.experiences h4 {
  padding-bottom: .5rem;
  font-size: 1.25rem;
  color: #6fc023;
}

.experiences li {
  margin-left: 2rem;
}

/* Reveal Css */

.motion-div {
  position: absolute;
  top: 0.25rem;
  bottom: 0.25rem;
  left: 0;
  right: 0;
  background-color: #038f03;
  z-index: 20;
}

/* Contact Css */
.contact {
  background: linear-gradient(
    90.21deg,
    #000000 -5.91%,
    #162811 50%,
    #f7e79e 111.58%
  );
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}
.contact p {
  text-align: left;
}
.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  width: 100%;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
